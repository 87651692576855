import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
var _templateObject, _templateObject2;
import '@fontsource/roboto/latin-300.css';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { css, Global } from '../../util/emotionReact';
import { jsx as ___EmotionJSX } from "@emotion/react";
var reset = css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  /* http://meyerweb.com/eric/tools/css/reset/ \n   v2.0 | 20110126\n   License: none (public domain)\n*/\n\n  html,\n  body,\n  div,\n  span,\n  applet,\n  object,\n  iframe,\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6,\n  p,\n  blockquote,\n  pre,\n  a,\n  abbr,\n  acronym,\n  address,\n  big,\n  cite,\n  code,\n  del,\n  dfn,\n  em,\n  img,\n  ins,\n  kbd,\n  q,\n  s,\n  samp,\n  small,\n  strike,\n  strong,\n  sub,\n  sup,\n  tt,\n  var,\n  b,\n  u,\n  i,\n  center,\n  dl,\n  dt,\n  dd,\n  ol,\n  ul,\n  li,\n  fieldset,\n  form,\n  label,\n  legend,\n  table,\n  caption,\n  tbody,\n  tfoot,\n  thead,\n  tr,\n  th,\n  td,\n  article,\n  aside,\n  canvas,\n  details,\n  embed,\n  figure,\n  figcaption,\n  footer,\n  header,\n  hgroup,\n  menu,\n  nav,\n  output,\n  ruby,\n  section,\n  summary,\n  time,\n  mark,\n  audio,\n  video {\n    margin: 0;\n    padding: 0;\n    border: 0;\n    font-size: 100%;\n    font: inherit;\n    vertical-align: baseline;\n  }\n  /* HTML5 display-role reset for older browsers */\n  article,\n  aside,\n  details,\n  figcaption,\n  figure,\n  footer,\n  header,\n  hgroup,\n  menu,\n  nav,\n  section {\n    display: block;\n  }\n  body {\n    line-height: 1;\n  }\n  ol,\n  ul {\n    list-style: none;\n  }\n  blockquote,\n  q {\n    quotes: none;\n  }\n  blockquote:before,\n  blockquote:after,\n  q:before,\n  q:after {\n    content: '';\n    content: none;\n  }\n  table {\n    border-collapse: collapse;\n    border-spacing: 0;\n  }\n"])));
var base = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n  html {\n    font-size: 62.5%; // 1rem ~= 10px\n    scroll-behavior: smooth;\n  }\n\n  h1 {\n    font-size: 3.6rem;\n  }\n  h2 {\n    font-size: 2.7rem;\n  }\n  h3 {\n    font-size: 2.106rem;\n  }\n  h4 {\n    font-size: 2.016rem;\n  }\n  h5 {\n    font-size: 1.494rem;\n  }\n  h6 {\n    font-size: 1.35rem;\n  }\n\n  body {\n    font-family: 'Roboto', sans-serif;\n    font-size: 1.9rem;\n    font-weight: 300;\n  }\n"])));
var Base = function Base(_ref) { var children = _ref.children; return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Global, { styles: reset }), ___EmotionJSX(Global, { styles: base }), ___EmotionJSX(Helmet, { htmlAttributes: { lang: "en" } }), children); };
export default Base;
